/**
 * 示例-编辑表单
 * luxinwen
 * 2023-01
 */
<template>
  <div>
    <Drawer class="drawer" :title="title" v-model="myValue" width="600" :before-close="beforeClose">
      <Form ref="editForm" :model="formData" :rules="formRule" :label-width="100">
        <FormItem label="商品名称" prop="name">
          <Input v-model.trim="formData.name" maxlength="30" clearable :disabled="isShow" />
        </FormItem>
        <FormItem label="商品编码" prop="code">
          <Input v-model.trim="formData.code" maxlength="30" clearable :disabled="isShow" />
        </FormItem>
        <FormItem label="商品状态" prop="status">
          <Select v-model="formData.status" clearable :disabled="isShow">
            <Option v-for="(value, key) in statusList" :key="'status' + key" :value="Number(key)">{{ value }}</Option>
          </Select>
        </FormItem>
      </Form>
      <div class="drawer-foot">
        <Button type="primary" @click="submit">提交</Button>
      </div>
      <Spin size="large" fix v-if="isLoading"></Spin>
    </Drawer>
  </div>
</template>

<script>
  import { TEST_STATUS } from '@/util/enum';
  const formData = {
    name: '',
    code: '',
    status: ''
  };

  export default {
    props: {
      value: {
        type: Boolean,
        default: false
      },
      data: {
        type: Object,
        default() {
          return {};
        }
      },
      flag: {
        type: String
      }
    },
    data() {
      return {
        myValue: this.value,  // 是否显示抽屉
        isInit: false,        // 是否初始化
        isLoading: false,     // 是否加载中
        formData: Object.assign({}, formData),
        formRule: {
          name: [
            {
              required: true,
              message: '请输入商品名称',
              trigger: 'blur'
            }
          ],
          code: [
            {
              required: true,
              message: '请输入商品编码',
              trigger: 'blur'
            }
          ],
          status: [
            {
              type: 'number',
              required: true,
              message: '请选择商品状态',
              trigger: 'change'
            }
          ]
        },
        backupData: {},
        statusList: TEST_STATUS
      };
    },
    computed: {
      title() {
        return {
          'add': '新增',
          'show': '查看',
          'edit': '编辑'
        }[this.flag] + '信息';
      },
      isEqual() {
        return this.deepEqual(this.formData, this.backupData);
      },
      isAdd() {
        return this.flag === 'add';
      },
      isShow() {
        return this.flag === 'show';
      },
      isEdit() {
        return this.flag === 'edit';
      }
    },
    methods: {
      /**
       * 初始化数据
       */
      initData(data = {}, isInit = false) {
        this.formData = this.deepCopy(formData);
        Object.keys(this.formData).forEach(item => {
          if (data.hasOwnProperty(item)) {
            this.formData[item] = data[item];
          }
        });
        if (isInit) {
          this.$nextTick(() => {
            this.backupData = this.deepCopy(this.formData);
            this.isInit = true;
          });
        }
      },
      /**
       * 获取数据
       */
      getData() {
        // 直接从列表页面通过row传参进来
        // this.initData(this.data, true);

        // 需要通过详情接口获取数据
        if (this.isAdd) {
          this.initData(this.data, true);
          return;
        }
        this.$axios({
          url: this.$api.test.queryTestDetail,
          mock: true,
          data: {
            id: this.data.id
          },
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          let _data = Object.assign({}, this.data, data);
          this.initData(_data, true);
        });
      },
      /**
       * 关闭前的回调
       */
      beforeClose() {
        return new Promise((resolve, reject) => {
          if (!this.isInit) {
            // 如果未初始化时快速点击关闭，则不做处理
          } else if (this.isEqual) {
            this.myValue = false;
          } else {
            this.$confirm('数据变更后未保存，是否直接关闭？').then(() => {
              this.myValue = false;
            });
          }
        });
      },
      /**
       * 提交
       */
      submit() {
        this.$refs.editForm.validate(valid => {
          if (valid) {
            this.$confirm('确定要提交吗？').then(() => {
              let url = this.$api.test.addTest;
              let params = Object.assign({}, this.formData);
              if (this.isEdit) {
                url = this.$api.test.updateTest;
                params.id = this.data.id;
              }
              this.save(url, params);
            });
          }
        });
      },
      /**
       * 保存
       */
      save(url, data) {
        this.$axios({
          url,
          data,
          beforeSend: () => {
            this.isLoading = true;
          },
          complete: () => {
            this.isLoading = false;
          }
        }).then(data => {
          this.notice({
            type: 'success',
            desc: '提交成功'
          });
          this.myValue = false;
          this.$emit('success');
        });
      }
    },
    watch: {
      value(val) {
        this.myValue = val;
        if (this.myValue) {
          // 获取数据
          this.getData();
          // 隐藏页面滚动条
          document.querySelector('body').classList.add('hidden');
          // 重置内容域滚动条
          this.$nextTick(() => {
            let $drawer = document.querySelector('.drawer .ivu-drawer-body');
            if ($drawer) $drawer.scrollTo(0, 0);
          });
        } else {
          // 清除表单验证效果
          this.$refs.editForm && this.$refs.editForm.resetFields();
          // 重置初始化状态
          this.isInit = false;
          // 恢复页面滚动条
          document.querySelector('body').classList.remove('hidden');
        }
      },
      myValue(val) {
        this.$emit('input', val);
      }
    }
  };
</script>